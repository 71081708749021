

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DuplicateButton extends Vue {
  @Prop({ default: "Duplicate" }) tooltip!: string
  @Prop({ default: false }) disabled!: boolean

  onButtonClicked (): void {
    this.$emit("btn-clicked")
  }
}
