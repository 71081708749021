

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PreviewButton extends Vue {
  @Prop({ required: false }) href!: string
  @Prop({ required: false }) route!: string
  @Prop({ default: "Preview" }) tooltip!: string
  @Prop({ default: false }) disabled
}
