
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'sf-alert'
})
export default class CopyURLButton extends Vue {
  @Prop({ default: "default" }) variant: string
  @Prop({ default: false }) emitOnly
  @Prop({ required: false }) copyText: string
  @Prop({ required: false }) tooltip: string
  @Prop({ default: "copy-url-icon" }) iconClass: string

  showCopied = false

  get buttonClass () {
    if (this.iconClass === "fal fa-window") {
      return 'widget-button'
    }
    return 'copy-url-button'
  }

  buttonClicked (): void {
    if (this.emitOnly) {
      this.$emit("btn-clicked")
    } else {
      this.copyURL()
      this.$emit("btn-click")
    }
  }

  copyURL (): void {
    const copyText = this.copyText ? this.copyText : window.location.href
    navigator.clipboard.writeText(copyText)
    new Promise(resolve => {
      this.showCopied = true
      setTimeout(resolve, 4000)
    }).then(() => {
      this.showCopied = false
    })
  }
}
