

import { Mixins, Component, Prop, Ref } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import { IProjectCategory } from '@/types/projects'
import { ISubCategory } from '@/types/foerderApp'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import { BDropdown } from 'bootstrap-vue'

@Component({
  mixins: [UserMixin]
})
export default class AddSubCategory extends Mixins(UserMixin) {
  @Ref('dropdown') readonly dropdown!: BDropdown | null
  @Prop() projectCategory: IProjectCategory
  @Prop() instance: { type: string, slug: string }
  subCategories: ISubCategory[] = []
  availableSubCategories: ISubCategory[] = []
  search = ""

  get chooseTagsText (): string {
    return this.$gettextInterpolate(this.$gettext('Choose tags in category: %{projectCategoryTitle}'), { projectCategoryTitle: this.projectCategory.title })
  }

  get filteredSubCategories (): ISubCategory[] {
    return this.availableSubCategories.filter(option => option.title.toLowerCase().includes(this.search.toLowerCase()))
  }

  mounted (): void {
    this.getAvailableSubCategories()
  }

  getAvailableSubCategories (): void {
    axios.get(API_URLS.SUB_CATEGORIES.LIST_FOR_PROJECT_CATEGORY(this.projectCategory.slug))
      .then(response => {
        this.availableSubCategories = response.data.results.filter(subCategory => !subCategory.projects.includes(this.instance.slug))
        this.subCategories = response.data.results.filter(subCategory => subCategory.projects.includes(this.instance.slug))
      })
  }

  subcategoryExists(subCategoryTitle: string): boolean {
    return this.subCategories.some(tag => tag.title === subCategoryTitle) || this.availableSubCategories.some(tag => tag.title === subCategoryTitle)
  }

  addTag (title: string): void {
    const data = {
      title,
      project_category: this.projectCategory.slug,
      instance_type: this.instance.type,
      instance_slug: this.instance.slug
    }
    axios.post(API_URLS.SUB_CATEGORIES.ADD, data)
      .then(response => {
        this.search = ''
        this.subCategories.push(response.data)
        this.availableSubCategories = this.availableSubCategories.filter(option => option.slug !== response.data.slug);
        (this.$refs.dropdown as BDropdown).hide()
      })
  }

  removeTag (subCategory: ISubCategory): void {
    this.subCategories = this.subCategories.filter(tag => tag.slug !== subCategory.slug)
    this.availableSubCategories.push(subCategory)
    const data = {
      data: {
        instance_type: this.instance.type,
        instance_slug: this.instance.slug
      }
    }
    axios.delete(API_URLS.SUB_CATEGORIES.REMOVE(subCategory.slug), data)
  }
}
