
import DeleteModal from '@/components/modals/DeleteModal.vue'
import { INavigationLink } from '@/types/foerderApp'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {
    DeleteModal
  }
})
export default class NavigationLink extends Vue {
  @Prop({ required: false }) initialLink: INavigationLink
  @Prop() instanceSlug: string
  @Prop() instanceType: string

  formError = ''

  @Watch('initialLink')
  onCurrentPageChanged () {
    this.setLink()
  }

  deleteModalOpen = false
  edit = false

  link: INavigationLink = {
    id: '',
    name: '',
    href: '',
    download: false
  }

  get formHasError () {
    return this.formError ? false : null
  }

  mounted () {
    if (this.initialLink) {
      this.setLink()
    }
  }

  setLink () {
    this.link.id = this.initialLink.id
    this.link.name = this.initialLink.name
    this.link.href = this.initialLink.href
    this.link.download = this.initialLink.download
    this.edit = true
  }

  get placeHolderHref () {
    return 'https://www.example.com/index'
  }

  get placeHolderName () {
    return this.$gettext('Our website')
  }

  reset () {
    this.link.name = ''
    this.link.href = ''
    this.link.download = false
    this.deleteModalOpen = false
    this.formError = ''
  }

  openDelteModal () {
    this.deleteModalOpen = true
  }

  createLink () {
    const formData = new FormData()
    formData.append('link', JSON.stringify(this.link))
    formData.append('instance_slug', this.instanceSlug)
    formData.append('instance_type', this.instanceType)
    axios.post(API_URLS.NAVIGATION_LINK.LIST, formData).then(response => {
      this.$emit("links-changed", response.data.links)
      this.reset()
    }).catch(error => {
      if (error.response && error.response.data) {
        this.formError = error.response.data.msg.href[0]
      }
    })
  }

  updateLink () {
    const formData = new FormData()
    formData.append('link', JSON.stringify(this.link))
    formData.append('instance_slug', this.instanceSlug)
    formData.append('instance_type', this.instanceType)
    axios.put(API_URLS.NAVIGATION_LINK.RETRIEVE(this.link.id), formData).then(response => {
      this.$emit("links-changed", response.data.links)
    }).catch(error => {
      if (error.response && error.response.data) {
        this.formError = error.response.data.msg.href[0]
      }
    })
  }

  deleteLink () {
    const formData = new FormData()
    formData.append('instance_slug', this.instanceSlug)
    formData.append('instance_type', this.instanceType)
    axios.delete(API_URLS.NAVIGATION_LINK.RETRIEVE(this.link.id), { data: formData }).then(response => {
      this.$emit("links-changed", response.data.links)
      this.reset()
    })
  }
}
