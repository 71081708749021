

import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import { IPhoto } from '@/types/photos'
import DeleteModal from '../modals/DeleteModal.vue'
import EditButton from '../buttons/EditButton.vue'
import DeleteButton from '../buttons/DeleteButton.vue'
import UserMixin from '@/mixins/UserMixin'
import * as tus from 'tus-js-client'
import AdministrateVideosModal from './AdministrateVideosModal.vue'

@Component({
  components: {
    DeleteModal,
    EditButton,
    DeleteButton,
    AdministrateVideosModal
  }
})
export default class FileItem extends Mixins(UserMixin) {
  @Prop({ required: true }) image: IPhoto
  @Prop({ required: false }) index: number
  @Prop({ default: false }) isFile
  @Prop({ default: false }) isLogo

  desc = ""
  videoUrl = ""
  orderInAlbum = ""
  formDisabled = true
  showOverlay = false
  deleteModalOpen = false
  videoUrlError = null
  formErrorVideoUrl = ''
  descError = ''

  videoUploadSize = 0
  currentlyUploaded = 0
  videoUploadPercentage = 0
  uploadVideoError = ''
  showAdministratedVideosModal = false
  videoUploadCompleted = false

  get uploadVideoErrorState () {
    return this.uploadVideoError ? false : null
  }

  get showStremioUpload () {
    return this.videoUrl === null || this.videoUrl === ''
  }

  @Watch('image', { deep: true })
  onImageChanged () {
    this.desc = this.image.description
    this.videoUrl = this.image.video
    this.orderInAlbum = this.image.order_in_album
  }

  get showDescription (): boolean {
    return !this.isFile && !this.isLogo
  }

  get isTitleImage (): boolean {
    return this.image.order_in_album?.toString() === "1"
  }

  get previewSrc (): string {
    // todo add default image for files
    return this.isFile ? '' : this.image.src
  }

  get userIsOrgaUser () {
    return this.userProfile?.usertype?.choice === 4
  }

  mounted (): void {
    this.fetchUserProfile()
    this.desc = this.image.description
    this.videoUrl = this.image.video
    this.orderInAlbum = this.image.order_in_album
  }

  deleteFile (): void {
    axios.delete(API_URLS.PHOTOS.DELETE(), { data: { slug: this.image.slug } }).then(() => {
      this.$emit('file-deleted', this.image)
    })
  }

  updatePhotoFields (): Promise<boolean> {
    if (this.desc.length > 100) {
      this.descError = this.$gettext('Description is too long. Max 100 characters allowed.')
      return
    }
    this.descError = ''
    return new Promise((resolve) => {
      const formData = new FormData()
      formData.append('description', this.desc)
      const videoUrl = this.videoUrl ? this.videoUrl : ''
      formData.append('video_redirect_url', videoUrl)
      const url = this.userIsOrgaUser ? API_URLS.PHOTOS.RETRIEVE(this.image.slug) + '?own' : API_URLS.PHOTOS.RETRIEVE(this.image.slug)
      axios.put(url, formData).then(() => {
        this.formDisabled = true
        // todo show success msg
        resolve(true)
      }).catch((error) => {
        this.formErrorVideoUrl = error.response.data.video_redirect_url[0]
        this.videoUrlError = false
        resolve(false)
      })
    })
  }

  reset (): void {
    this.formDisabled = true
    this.videoUrlError = null
    if (this.desc !== this.image.description) {
      this.desc = ""
    }
    if (this.videoUrl !== this.image.video && this.image.video !== null) {
      this.videoUrl = ""
    }
    if (this.orderInAlbum !== this.image.order_in_album) {
      this.orderInAlbum = ""
    }
  }

  onActionAborted (): void {
    this.reset()
  }

  async recropImage (image: IPhoto) {
    if (await this.updatePhotoFields()) {
      this.$emit('recrop-image', image)
    }
  }

  enableForm (): void {
    this.formDisabled = false
    this.showOverlay = true
  }

  toggleOverlay (show: boolean) {
    if (this.formDisabled) {
      this.showOverlay = show
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async fetchStreamioData (): Promise<{ status: string, token: string, tus_endpoint: string, pull_endpoint: string } | any> {
    const response = await axios.post(API_URLS.PHOTOS.STREAMIO_TOKEN_FOR_USER_UPLOAD(this.image.slug)).then(response => {
      return response
    }).catch(error => {
      return error
    })
    return response
  }

  resetVideoUpload (): void {
    this.videoUploadSize = 0
    this.currentlyUploaded = 0
    this.videoUploadPercentage = 0
    this.uploadVideoError = ''
    this.showAdministratedVideosModal = false
    setTimeout(() => {
      this.videoUploadCompleted = false
    }, 200)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async fetchVideoData (): Promise<any> {
    const response = await axios.post(API_URLS.PHOTOS.STREAMIO_VIDEO(this.image.slug)).then(response => {
      this.videoUploadCompleted = true
      this.resetVideoUpload()
      return response
    }).catch(error => {
      return error
    })
    return response
  }

  async uploadVideoWithTus (file: File, token: string, url: string): Promise<void> {
    const options = {
      endpoint: url,
      metadata: {
        filename: file.name,
        filetype: file.type,
        uploadtoken: token
      },
      uploadSize: file.size,
      onProgress: (bytesUploaded: number, bytesTotal: number) => {
        const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
        this.videoUploadSize = bytesTotal
        this.currentlyUploaded = bytesUploaded
        this.videoUploadPercentage = parseFloat(percentage)
      },
      onSuccess: async () => {
        await this.fetchVideoData()
      }
    }

    const upload = new tus.Upload(file, options)
    upload.start()
  }

  async handleVideoUpload (event: Event): Promise<void> {
    const input = event.target as HTMLInputElement
    const file = input.files && input.files[0]
    if (!file) {
      return
    }
    const stremioData = await this.fetchStreamioData()

    if (stremioData.status !== 200) {
      return
    }

    const token = stremioData.data.token
    const tusUrl = stremioData.data.tus_endpoint

    if (token === '' || token === null || token === undefined || token === 'null' || token === 'undefined') {
      return
    }
    await this.uploadVideoWithTus(file, token, tusUrl)
  }

  handleVideoSelected (url): void {
    this.videoUrl = url
    this.updatePhotoFields()
  }

  handleVideoDeleted (photos: IPhoto[]): void {
    this.$emit("video-deleted", photos)
  }
}
