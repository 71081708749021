

import { Component, Prop, Watch, Ref, Mixins } from 'vue-property-decorator'
import { BvTableCtxObject, BvComponent, BvTableField } from 'bootstrap-vue'
import InputGroup from '@/components/InputGroup.vue'
import { IFilter, IFilterInit, IFilters } from '@/types/filters'
import PageSizeSelect from '@/components/PageSizeSelect.vue'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import axios from 'axios'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { addContextToUrl } from '@/utils/helpers'
import { BvTableFieldArrayWithStickColumn, TGenericObject } from '@/types/base'
import LocaleMixin from '@/mixins/LocaleMixin'

@Component({
  components: {
    InputGroup,
    TableFilterDropdown,
    PageSizeSelect
  }
})
export default class GenericTable extends Mixins(LocaleMixin) {
  // Table field definitions. Check https://bootstrap-vue.js.org/docs/components/table/ for more
  @Ref() readonly selectableTable!: BvComponent
  @Prop({ default: false }) archived!: boolean
  @Prop() fields!: BvTableFieldArrayWithStickColumn
  @Prop({ default: () => [] }) customFields!: string[]
  @Prop({ default: '' }) customClasses!: string
  @Prop({ default: false }) bordered!: boolean
  @Prop() searchPlaceholder: string
  @Prop() tableName: string
  @Prop({ default: null }) localStorageName!: string | null
  @Prop() apiUrl!: string
  @Prop() apiUrlExtraParams!: TGenericObject
  @Prop({ default: () => [] }) filterCols: { field: string, filter: string, name?: string }[]
  @Prop({ default: false }) hideSearch!: boolean
  @Prop({ default: false }) hideFilterOptions!: boolean
  @Prop({ default: 25 }) pageSize!: number
  @Prop({ default: false }) small!: boolean
  @Prop({ default: false }) noEnumeration!: boolean
  @Prop({ default: false }) selectable: boolean
  @Prop({ default: () => [] }) sumFields: string[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ required: false }) modifyTableItemsFunc: Function

  // Define filter options. Those that we see in the dropdowns
  @Prop({ default: () => ({}) }) filters: IFilters
  /* // Example how to define filters:
  filters: IFilters = {
    itemFieldFilter: {
      filterName: 'field_name',
      selected: [],
      options: {
        option_1: this.$gettext('Option 1'),
        option_2: this.$gettext('Option 2')
      }
    }
  } */
  tableItems: TGenericObject[] = []
  searchString!: string
  currentPage = 1
  totalPages = 1
  itemCount = 0
  activeFilters: IFilter[] = []
  tableContext: BvTableCtxObject | null = null
  tableLoading = false
  hoveredRowId = 0
  selectedItems: TGenericObject[] = []
  sumOfAllResults = {}

  handleSearchChange (value: string) {
    this.searchString = value
    this.$emit('search-update', this.searchString)
    this.currentPage = 1
    this.loadItems()
  }

  get pageSizeModel () {
    return this.pageSize
  }

  set pageSizeModel (value: number) {
    this.$emit("update:pageSize", value)
  }

  @Watch('itemCount')
  onItemCountChanged () {
    this.$emit("item-count-update", this.itemCount)
  }

  @Watch('pageSize')
  onPageSizeChanged () {
    this.loadItems()
  }

  @Watch('currentPage')
  onCurrentPageChanged () {
    if (!this.tableLoading) this.loadItems()
  }

  sortingChanged (ctx: BvTableCtxObject) {
    this.tableContext = ctx
    this.loadItems()
  }

  applyFilter (filter: IFilter | IFilter[]) {
    if ('filterName' in filter) {
      this.activeFilters = this.activeFilters.filter(_filter => _filter.filterName !== filter.filterName)
      this.activeFilters.push(filter)
    } else {
      for (const filterItem of filter) {
        this.activeFilters = this.activeFilters.filter(_filter => _filter.filterName !== filterItem.filterName)
        this.activeFilters.push(filterItem)
      }
    }
    if (this.localStorageName) { localStorage[this.localStorageName] = JSON.stringify(this.activeFilters) }
    this.$emit('filter-update', this.activeFilters)
    this.currentPage = 1
    this.loadItems()
  }

  getFilter (filterName: string): IFilterInit {
    return this.filters[filterName]
  }

  getFieldName (field: string | ({ key: string } & {stickyColumn?: boolean} & BvTableField)): string {
    return typeof field !== 'string' ? field.key : field
  }

  get computedFields (): BvTableFieldArrayWithStickColumn {
    // add a generic actions column that is basically not visible to add row actions
    const finalFields = this.fields.slice()
    if (!this.noEnumeration) {
      finalFields.unshift({ key: 'index', label: this.$gettext('#') })
    }
    if (this.selectable) {
      finalFields.unshift({ key: 'selected', label: 'Select All', sortable: false })
    }
    return finalFields
  }

  get emptyText (): string {
    return this.$gettext('No entries found')
  }

  getSearchPlaceholder (): string {
    return this.searchPlaceholder ? this.searchPlaceholder : this.$gettext('Search…')
  }

  toggleCheckAll (val: boolean | string): void {
    if (val) this.selectableTable.selectAllRows()
    else this.selectableTable.clearSelected()
  }

  onRowSelected (items: TGenericObject[]) {
    this.selectedItems = items
    this.$emit('update:selectedItems', items)
  }

  toggleSelect (rowIndex: number): void {
    if (!this.selectableTable.isRowSelected(rowIndex)) this.selectableTable.selectRow(rowIndex)
    else this.selectableTable.unselectRow(rowIndex)
  }

  get allVisibleItemsSelected (): boolean {
    return this.tableItems.length <= this.selectedItems.length
  }

  paginationSum (param: string): string {
    const summaries = []
    let filteredSum = []

    this.tableItems.forEach(element => {
      const elem = { ...element, param }
      summaries.push(elem[param])
    })

    filteredSum = summaries.filter(element => {
      return element !== null
    })
    const sum = filteredSum.reduce(
      (acc, curr) => acc + curr.in_currency, 0
    )
    return this.toCurrency(sum)
  }

  async loadItems (): Promise<void> {
    if (!this.apiUrl) return
    this.tableLoading = true
    await axios.get(
      addContextToUrl(this.apiUrl, {
        sortBy: this.tableContext ? this.tableContext.sortBy : undefined,
        sortDesc: this.tableContext ? this.tableContext.sortDesc : undefined,
        search: this.searchString,
        filters: this.activeFilters,
        page: this.currentPage,
        pageSize: this.pageSizeModel,
        extraParams: this.apiUrlExtraParams
      })
    ).then(response => {
      this.totalPages = response.data.total_pages
      this.currentPage = response.data.current_page
      if (this.modifyTableItemsFunc) {
        this.tableItems = this.modifyTableItemsFunc(response.data.results)
      } else {
        this.tableItems = response.data.results
      }
      this.itemCount = response.data.count
      for (const sumField of this.sumFields) {
        this.sumOfAllResults[sumField] = response.data['total_' + sumField]
      }
      this.$emit('loaded', response.data.results)
    }).catch(() => {
      this.$bvToast.toast(this.$gettext('Please try again or contact an administrator.'), {
        title: this.$gettext('Error'),
        toaster: 'b-toaster-top-left old',
        variant: 'variant',
        solid: true,
        toastClass: 'sf-toast'
      })
    })
    this.tableLoading = false
  }

  initLocalStorage (): void {
    if (this.localStorageName) {
      try {
        this.activeFilters = JSON.parse(localStorage[this.localStorageName])
        const activeFilterNames = this.activeFilters.map(filter => filter.filterName)
        const activeFiltersObj = this.activeFilters.reduce((result, item: IFilter) => {
          result[item.filterName] = item.selected
          return result
        }, {})
        for (const filterKey in this.filters) {
          const filter = this.filters[filterKey]
          if (activeFilterNames.includes(filter.filterName)) {
            filter.selected = activeFiltersObj[filter.filterName]
          }
        }
      } catch (e) {
        localStorage.removeItem(this.localStorageName)
      }
    }
  }

  async loadInitialItems (): Promise<void> {
    this.$wait.start('fetch items')
    // initialize pre-selected filters
    for (const filterKey in this.filters) {
      const filter = this.filters[filterKey]
      if ('selected' in filter) {
        this.activeFilters.push(filter)
      }
    }
    await Promise.all([
      this.loadItems()
    ])
    this.$wait.end('fetch items')
  }

  async created (): Promise<void> {
    moment.locale()
    this.pageSizeModel = this.pageSize
    this.initLocalStorage()
    await this.$nextTick()
    this.loadInitialItems()
    this.handleSearchChange = debounce(this.handleSearchChange, 500)
  }
}
