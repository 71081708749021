
import DeleteModal from '@/components/modals/DeleteModal.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import NavigationLink from './NavigationLink.vue'
import { INavigationLink } from '@/types/foerderApp'
@Component({
  components: {
    DeleteModal,
    NavigationLink
  }
})
export default class NavigationLinks extends Vue {
  @Prop({ required: false }) currentLinks: INavigationLink[]
  @Prop() instanceSlug: string
  @Prop() instanceType: string
  displayedLinks: INavigationLink[] = []

  fadeClass = ''

  mounted () {
    this.displayedLinks = this.currentLinks
  }

  onLinkCreated (responseData, link = null) {
    this.$emit('link-created', link)
    this.fadeOutAndIn()
  }

  onLinkUpdated (responseData) {
    this.displayedLinks = responseData
    this.fadeOutAndIn()
  }

  onLinkDeleted (responseData) {
    this.currentLinks = responseData
    this.fadeOutAndIn()
  }

  fadeOutAndIn () {
    this.fadeClass = 'fade-element'
    setTimeout(() => {
      this.fadeClass = ''
    }, 1000)
  }
}
